
import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const DetailScreen = () => {
  const [stock, set_data] = useState<any>()
  const { stock_id } = useParams()
  const { data: data_stocks, search_osrm_action: search_osrm_action_stocks } = useSearchMiddlewareOSRM({
    entity: "stocks",
  })

  useEffect(() => {
    search_osrm_action_stocks({ from: 0, size: 1, filters: { id: stock_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_stocks?.records.length)) {
      const get_stock = async () => {
        let stock_from_req = data_stocks?.records[0]
        set_data(stock_from_req)
      }
      get_stock()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_stocks])

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de stock", href: "/stock" },
          { name: "Detalle de stock", href: `/stock/${stock_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de stock #${stock_id}`} />
        {/* <p>SourceDetailScreen</p> */}
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de stock</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{stock_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Nombre de stock</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{stock?.name}</p>
            </div>
          </div>
          <CollapseWithDetail title="Stock de seguridad" details={stock?.safety} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            {/* <h4 className="font-semibold text-[#4C4C4C]">Detalles</h4> */}
          </div>
          <CollapseWithDetail title="Sources" details={stock?.sources} />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
