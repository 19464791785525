import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import { SOURCE_TYPE } from "components/FormEntities/FormSource/source.config"
import { Button } from "flowbite-react"
import useCombinedSourcesBFF from "hooks/bff_entities/useCombinedSourcesBFF"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"

const DetailScreen = () => {
  const navigate = useNavigate()
  const [source, set_source] = useState<any>()
  const { source_id } = useParams()
  const { set_loading_app, post_list_source_combined_action } = useCombinedSourcesBFF()

  useEffect(() => {
    const get_source = async () => {
      try {
        set_loading_app(true)
        const res: any = await post_list_source_combined_action({ body: { skipRecords: 0, maxRecords: 10, filter: { id: source_id } } })
        set_source(res?.data?.message?.records[0] || null)
        set_loading_app(false)
      } catch (error: any) {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_source()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!source) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de sources", href: "/sources" },
          { name: "Detalle de source", href: `/sources/${source_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de source #${source_id}`} />
        <div className="w-full flex flex-row justify-end items-center">
          <Button label="Editar" onClick={() => navigate(`sources/${source_id}/edit`)} />
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de source</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Nombre</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.name}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">
                {SOURCE_TYPE.find((src: any) => src?.value === source?.type)?.label}
              </p>
              {Boolean(source?.type === "virtual") ? (
                <>
                  <p className="text-sm text-[#4C4C4C] px-4 py-3">Enlace Físico</p>
                  <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.physicalLink}</p>
                </>
              ) : null}
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Retiro en tienda</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.storePickup ? "Si" : "No"}</p>
            </div>
          </div>
          <CollapseWithDetail title="Ubicación" details={source?.location} />
          <CollapseWithDetail title="Capacidad de volumen" details={source?.volumeCapacity} />
          <CollapseWithDetail title="Capacidad de despacho" details={source?.dispatchCapacity} />
          <CollapseWithDetail title="Crossdock" details={source?.crossdock} />
          <CollapseWithDetail title="Jornada de trabajo general del source" details={source?.workingTime} />
          <CollapseWithDetail title="Jornada de trabajo por tipo de producto" details={source?.workingSessions} />
          <CollapseWithDetail title="Custom" details={source?.custom} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle del source</h4>
            <div className="grid grid-cols-2 grid-rows-4 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Alias</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.alias}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado del source</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.enabled ? "Habilitado" : "Deshabilitado"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Stock de seguridad</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.safety}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Ranking</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.ranking}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Sitio</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{source?.site}</p>
            </div>
          </div>
          <CollapseWithDetail title="Leadtime" details={source?.leadTime} />
          <CollapseWithDetail title="Agendamiento" details={source?.schedule} />
          <CollapseWithDetail title="Configuración del calendario" details={source?.calendarSettings} />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
