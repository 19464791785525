import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormRoute } from "components/FormEntities"
import useForm from "hooks/useForm"
import route_values from "utils/forms_default/route_values"
import schemaValidationRoutes from "utils/yup/routes_schema"

const NewScreen = () => {
  const { control, watch, setValue } = useForm({
    defaultValues: route_values,
    schema: schemaValidationRoutes,
  })

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Creación de ruta", href: "/routes/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de ruta" />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormRoute control={control} watch={watch} setValue={setValue} />
      </form>
    </div>
  )
}

export default NewScreen
