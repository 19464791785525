export const ROUTE_TYPE = [
  { id: 1, label: "Ubicación", value: "Location" },
  { id: 2, label: "Source", value: "Source" },
]

const load_data_to_fill = (type: string, data: any) => {
  const isSource = Boolean(type === "Source")
  if (isSource) {
    return { ...data, label: `${data?.name} (${data?.id})`, value: data?.id }
  }
  return data
}

export const fill_form_from_data = (_data: any) => {
  const { id, status, source, target } = _data

  let data = {
    id,
    status,
    source: {
      type: source?.type,
      data: load_data_to_fill(source?.type, source?.data),
    },
    target: {
      type: target?.type,
      data: load_data_to_fill(target?.type, target?.data),
    },
  }

  console.log("data", data)
  return data
}

export const parsed_body_to_send = (_data: any) => {}
