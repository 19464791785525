import { TextField } from "components/Core"
import { Controller } from "react-hook-form"

interface IPreviewData {
  control: any
  type: "source" | "target"
}

const PreviewData = ({ control, type }: IPreviewData) => {
  return (
    <div className="w-full flex flex-col">
      <Controller
        name={`${type}.data.criteria0`}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => <TextField label="Criteria0" onChange={onChange} value={value} disabled />}
      />
      <Controller
        name={`${type}.data.criteria1`}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => <TextField label="Criteria1" onChange={onChange} value={value} disabled />}
      />
      <Controller
        name={`${type}.data.criteria2`}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => <TextField label="Criteria2" onChange={onChange} value={value} disabled />}
      />
      <Controller
        name={`${type}.data.criteria3`}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => <TextField label="Criteria3" onChange={onChange} value={value} disabled />}
      />
    </div>
  )
}

export default PreviewData
