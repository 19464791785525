import { Breadcrumb, TitleScreen, Paginator, Table, Tooltip } from "components/Core"
import ButtonListNavigation from "components/ButtonListNavigation"
import SearchBarFilter from "components/SearchBarFilter"
import { useEffect, useState } from "react"
import { LuPencil } from "react-icons/lu"
import { get_data_table_orders } from "../../helpers/data_tables"
import usePaginationTable from "overrides/theme/entel/base/hooks/usePaginationTable"
import usePostMiddlewareQuery from "overrides/theme/entel/base/hooks/usePostMiddlewareQuery"
import useFiltersTable from "hooks/useFiltersTable"

const ListScreen = () => {
  const { post_middleware_query_action } = usePostMiddlewareQuery()
  const [data, set_data] = useState<any>()
  const [loading_app, set_loading_app] = useState<boolean>(false)

  const { current_data, current_page, handle_page_change, handle_per_page, per_page, total_records, total_pages } =
    usePaginationTable({
      data,
    })
    const { filters_table } = useFiltersTable({ table_name: "orders" })

  useEffect(() => {
    const post_req = async () => {
      set_loading_app(true)
      const res = await post_middleware_query_action({
        skipRecords: per_page * current_page,
        maxRecords: per_page,
        entityType: "Order",
        filter: filters_table.state,
      })
      set_data(res?.data?.message)
      set_loading_app(false)
    }
    post_req()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, per_page, filters_table.state])

  const [columns, data_table_orders] = get_data_table_orders(current_data)

  return (
    <div className="w-full h-full flex flex-col items-center justify-start pb-8">
      <Breadcrumb data={[{ name: "Listado de órdenes", href: "/orders" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="listado de órdenes" />
        {/* <p>SourceListScreen</p> */}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col lg:flex-row justify-between items-end lg:items-center gap-4 px-6">
          <SearchBarFilter />
          <ButtonListNavigation label="Crear Orden" route="/orders/new" />
        </div>
        <div className="w-full flex flex-col gap-2 px-6">
          <Paginator
            current_page={current_page}
            handle_page_change={handle_page_change}
            per_page={per_page}
            total_records={total_records}
            total_pages={total_pages}
            handle_per_page={handle_per_page}
          />
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                <Table.HeadCell className="!w-5">
                  <></>
                </Table.HeadCell>
                {columns?.map((key: string) => (
                  <Table.HeadCell>{key}</Table.HeadCell>
                ))}
              </Table.Head>
              <Table.Body>
                {!loading_app && data_table_orders?.length ? (
                  data_table_orders?.map((order: any, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell className="flex flex-row items-center justify-end gap-2">
                        <Tooltip content="Editar" align="top">
                          <a href={`/order/${order?.id}/edit`}>
                            <LuPencil className="pencil_edit opacity-0 text-lg cursor-pointer" />
                          </a>
                        </Tooltip>
                      </Table.Cell>

                      {Object.values(order).map((value: any, i: number) =>
                        i === 0 ? (
                          <Table.Cell key={i} mainCol>
                            <a href={`/orders/${value}`} className="text-[#F9004D] underline">
                              {value}
                            </a>
                          </Table.Cell>
                        ) : (
                          <Table.Cell key={i}>{value}</Table.Cell>
                        ),
                      )}
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>{loading_app ? "Espere..." : "Sin resultados"}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListScreen
