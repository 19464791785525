import React, { useState } from 'react';
import { IoIosArrowForward as ChevronRight, IoIosArrowDown as ChevronDown } from "react-icons/io"
import { FiInfo as Info } from "react-icons/fi";

type TranslationFunction = (key: string, value: any, path: string[]) => string;

type JsonViewerProps = {
  data: any;
  label?: string;
  depth?: number;
  path?: string[];
  translateKey?: TranslationFunction;
  translateValue?: TranslationFunction;
};

const JsonViewer: React.FC<JsonViewerProps> = ({ 
  data, 
  label, 
  depth = 0, 
  path = [], 
  translateKey = (key) => key,
  translateValue = (_, value) => String(value)
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const getDataType = (value: any): string => {
    if (Array.isArray(value)) return 'array';
    if (value === null) return 'null';
    return typeof value;
  };

  const renderValue = (value: any, key: string) => {
    const type = getDataType(value);
    const currentPath = [...path, key];
    const translatedKey = translateKey(key, value, currentPath);

    if (type === 'object' || type === 'array') {
      return (
        <JsonViewer
          data={value}
          label={translatedKey}
          depth={depth + 1}
          path={currentPath}
          translateKey={translateKey}
          translateValue={translateValue}
        />
      );
    }

    const translatedValue = translateValue(key, value, currentPath);

    return (
      <div className="flex items-center gap-4 py-3 px-4 hover:bg-gray-50 rounded-lg transition-colors group">
        <div className="flex-1">
          <div className="text-sm text-gray-500 mb-1">{translatedKey}</div>
          <div
            className={`
              font-medium text-base
              ${type === 'string' ? 'text-emerald-700' : ''}
              ${type === 'number' ? 'text-blue-700' : ''}
              ${type === 'boolean' ? 'text-purple-700' : ''}
              ${type === 'null' ? 'text-gray-400 italic' : ''}
            `}
          >
            {translatedValue}
          </div>
        </div>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity">
          <Info className="w-4 h-4 text-gray-400" />
        </div>
      </div>
    );
  };

  if (data === null || typeof data !== 'object') {
    return <div className="px-4 py-2">{translateValue(label || '', data, path)}</div>;
  }

  const entries = Object.entries(data);
  const isArray = Array.isArray(data);
  const isEmpty = entries.length === 0;
  const translatedLabel = label ? translateKey(label, data, path) : undefined;

  return (
    <div className={`${depth > 0 ? 'ml-6' : ''}`}>
      {translatedLabel && (
        <div className="mb-2">
          <div
            className="flex items-center gap-2 cursor-pointer py-2 px-3 hover:bg-gray-50 rounded-lg transition-colors"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {entries.length > 0 && (
              <span className="text-blue-500 bg-blue-50 rounded-full p-1">
                {isExpanded ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
              </span>
            )}
            <span className="font-semibold text-gray-700">{translatedLabel}</span>
            <span className="text-sm text-gray-400">
              {isArray ? 'Lista' : 'Grupo'} • {entries.length} {entries.length === 1 ? 'elemento' : 'elementos'}
            </span>
          </div>
        </div>
      )}

      {isExpanded && (
        <div className={`
          space-y-1 
          ${depth > 0 ? 'border-l-2 border-gray-100 pl-4' : ''}
          ${translatedLabel ? 'mt-2' : ''}
        `}>
          {isEmpty ? (
            <div className="text-gray-400 text-sm italic px-4 py-2">
              No hay {isArray ? 'elementos' : 'datos'} disponibles
            </div>
          ) : (
            <div className="bg-white rounded-xl">
              {entries.map(([key, value]) => (
                <div key={key} className="border-b last:border-b-0 border-gray-100">
                  {renderValue(value, key)}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JsonViewer;