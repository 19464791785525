import { FormSection, Select, Switch, TextField, SearchableSelect } from "components/Core"
import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import PreviewData from "./PreviewData"
import { ROUTE_TYPE } from "./route.config"

interface IFormRoute {
  control: any
  watch: any
  setValue: any
  edit?: boolean
  fillForm?: any
  route?: any
}

const FormRoute = ({ control, watch, route, edit, setValue }: IFormRoute) => {
  const [newLocationDataSource, setNewLocationDataSource] = useState(false)
  const [newLocationDataTarget, setNewLocationDataTarget] = useState(false)
  const [sourceSearch, setSourceSearch] = useState("")
  const [sourceResults, setSourceResults] = useState<any>([])
  const [targetSearch, setTargetSearch] = useState("")
  const [targetResults, setTargetResults] = useState<any>([])
  const [isSourceLocation, setIsSourceLocation] = useState(false)
  const [isTargetLocation, setIsTargetLocation] = useState(false)
  const [isLoadingSearchableSource, setIsLoadingSearchableSource] = useState(false)
  const [isLoadingSearchableTarget, setIsLoadingSearchableTarget] = useState(false)

  const values = watch()
  console.log("values", values)
  const sourceType = watch("source.type")
  const targetType = watch("target.type")

  useEffect(() => {
    if (!edit) {
      setNewLocationDataSource(true)
      setNewLocationDataTarget(true)
    }
    if (route && sourceType === "Source") {
      let src: any = route?.source?.data
      setSourceResults(src ? [{ value: src?.id, label: `${src?.name} (${src?.id})` }] : [])
    }
    if (route && targetType === "Source") {
      let src: any = route?.target?.data
      setTargetResults(src ? [{ value: src?.id, label: `${src?.name} (${src?.id})` }] : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, route])

  useEffect(() => {
    setSourceResults([])
    if (sourceType === "Location") {
      setIsSourceLocation(true)
      setValue("source.data", {})
    } else {
      setIsSourceLocation(false)
      setValue("source.data", [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType])

  useEffect(() => {
    setTargetResults([])
    if (targetType === "Location") {
      setIsTargetLocation(true)
      setValue("target.data", {})
    } else {
      setIsTargetLocation(false)
      setValue("target.data", [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetType])

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection title="Información general" helperText="Información general" number={1}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Id Ruta" onChange={onChange} value={value} />}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Estado de ruta" onChange={onChange} checked={value} />
              )}
            />
          </div>
        </div>
      </FormSection>
      <FormSection title="Source" helperText="Source" number={2}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="source.type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
            )}
          />
          {edit && isSourceLocation && !newLocationDataSource ? (
            <>
              <PreviewData control={control} type="source" />
              <div className="w-full flex items-center justify-end">
                <p onClick={() => setNewLocationDataSource(true)} className="text-[#F9004D] underline cursor-pointer">
                  Cambiar
                </p>
              </div>
            </>
          ) : isSourceLocation ? (
            <p>LoadCriterias</p>
          ) : (
            <Controller
              name="source.data"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <SearchableSelect
                  search={sourceSearch}
                  setSearch={setSourceSearch}
                  options={sourceResults}
                  setOptions={setSourceResults}
                  onChange={onChange}
                  value={value}
                  placeholder="Source"
                  isLoading={isLoadingSearchableSource}
                  setIsLoading={setIsLoadingSearchableSource}
                />
              )}
            />
          )}
        </div>
      </FormSection>
      <FormSection title="Target" helperText="Target" number={3}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="target.type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
            )}
          />
          {edit && isTargetLocation && !newLocationDataTarget ? (
            <>
              <PreviewData control={control} type="target" />
              <div className="w-full flex items-center justify-end">
                <p onClick={() => setNewLocationDataTarget(true)} className="text-[#F9004D] underline cursor-pointer">
                  Cambiar
                </p>
              </div>
            </>
          ) : isTargetLocation ? (
            <p>LoadCriterias</p>
          ) : (
            <Controller
              name="target.data"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <SearchableSelect
                  search={targetSearch}
                  setSearch={setTargetSearch}
                  options={targetResults}
                  setOptions={setTargetResults}
                  onChange={onChange}
                  value={value}
                  placeholder="Target"
                  isLoading={isLoadingSearchableTarget}
                  setIsLoading={setIsLoadingSearchableTarget}
                />
              )}
            />
          )}
        </div>
      </FormSection>
    </div>
  )
}

export default FormRoute
