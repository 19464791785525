import { groupBy } from "helpers/tools"

export const SOURCE_TYPE = [
  { id: 1, label: "Virtual", value: "virtual" },
  { id: 2, label: "Fisico", value: "physical" },
]

export const TYPE_DINAMIC = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

const parsedDinamicDataOnSave = (field: any) => {
  let values: any = {}
  ;(field || []).map((item: any) => {
    if (item?.key?.includes(".")) {
      let [key1, key2] = item?.key?.split(".")
      if (values.hasOwnProperty(key1)) {
        values[key1] = { ...values[key1], [key2]: assignDataType(item.type, item.value) }
      } else {
        Object.assign(values, { [key1]: { [key2]: assignDataType(item.type, item.value) } })
      }
      return true
    }
    Object.assign(values, { [item.key]: assignDataType(item.type, item.value) })
    return true
  })
  return values
}

const assignDataType = (type: any, value: any) => {
  try {
    switch (type) {
      case "array-string":
        return value && value?.split("+")
      case "number":
        return Number(value) || 0
      case "string":
        return value?.toString() || JSON.stringify(value)
      default:
        return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

export const TYPE = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

export const fill_form_from_data = (_data: any) => {
  const {
    id,
    name,
    alias,
    type,
    physicalLink,
    location,
    safety,
    ranking,
    storePickup,
    enabled,
    contact,
    custom,
    volumeCapacity,
    dispatchCapacity,
    schedule,
    crossdock,
    workingSessions,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    // 1
    id,
    name,
    alias: alias[0] || "",
    type,
    physicalLink,
    // 2
    location: loadDinamicFields(location),
    // location: Object.entries(location).map(([key, value]) => ({
    //   key,
    //   value,
    //   type: TYPE.find((ty: any) => ty?.value === ASSING_TYPEOF_LOAD(value))?.value,
    // })),
    // 3
    safety,
    ranking,
    storePickup,
    enabled,
    // 4
    contact: {
      name: contact?.name,
      phone: contact?.phone,
    },
    // 5
    custom: loadDinamicFields(custom),
    // custom: Object.entries(custom).map(([key, value]) => {
    //   return {
    //     key,
    //     value,
    //     type: TYPE.find((ty: any) => ty?.value === ASSING_TYPEOF_LOAD(value))?.value,
    //   }
    // }),
    // 6
    volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
      daysOfWeek: vc?.daysOfWeek.map((day: any) => Number(day)),
      overCapacity: Number(vc?.overCapacity),
      capacity: Number(vc?.capacity),
    })),
    // 7
    dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
      daysOfWeek: dp?.daysOfWeek.map((day: any) => Number(day)),
      deliveryType: dp?.deliveryType,
      productType: dp?.productType ? dp.productType?.join(",") : "",
      overCapacity: Number(dp?.overCapacity),
      capacity: Number(dp?.capacity),
    })),
    // 8
    schedule: (schedule || [])?.map((sch: any) => ({
      deliveryType: sch?.deliveryType,
      shippingType: sch?.shippingType ? sch?.shippingType?.map((shp: any) => shp) : [],
      productType: sch?.productType && sch?.productType?.join(","),
      leadtime: {
        value: Number(sch?.leadtime?.value),
        unit: sch?.leadtime?.unit,
      },
      deliverySlots: fill_form_delivery_slots_schedule_from_data(sch?.deliverySlots || []),
    })),
    // 9
    crossdock: (crossdock || []).map((crss: any) => ({
      productType: crss?.productType && crss?.productType?.join(","),
      value: Number(crss?.value),
      unit: crss?.unit,
    })),
    // 10
    workingSessions: (workingSessions || []).map((ws: any) => {
      let wsData = {
        productType: ws?.productType && ws?.productType?.join(","),
        capacity: Number(ws?.capacity),
        daysOfWeek: ws?.daysOfWeek.map((day: any) => Number(day)),
        from: ws?.from,
        to: ws?.to,
      }
      if (Boolean(ws?.lockTime)) {
        wsData = Object.assign(wsData, {
          enable_locktime: true,
          lockTime: {
            from: ws.lockTime?.from,
            to: ws.lockTime?.to,
          },
        })
      }
      return wsData
    }),
    // 11
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    // 12
    calendarSettings: {
      timezone: calendarSettings?.timezone,
      nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => Number(day)) || [],
      nonWorkingDates: calendarSettings?.nonWorkingDates || [],
    },
  }
  return data
}

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    name,
    alias,
    type,
    physicalLink,
    location,
    safety,
    ranking,
    storePickup,
    enabled,
    contact,
    custom,
    volumeCapacity,
    dispatchCapacity,
    schedule,
    crossdock,
    workingSessions,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    OIM: {
      // 1
      id,
      name,
      alias: [alias || ""],
      type,
      physicalLink: type === "virtual" ? physicalLink : "",
      // 2
      location: parsedDinamicDataOnSave(location),
      // 3
      safety: Number(safety),
      ranking: Number(ranking),
      storePickup,
      enabled,
      // 4
      contact,
      // 5
      custom: parsedDinamicDataOnSave(custom),
    },
    OLM: {
      // 1
      id,
      name,
      alias: [alias || ""],
      // 6
      volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
        daysOfWeek: vc?.daysOfWeek.map((day: any) => Number(day)),
        overCapacity: Number(vc?.overCapacity),
        capacity: Number(vc?.capacity),
      })),
      // 7
      dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
        daysOfWeek: dp?.daysOfWeek.map((day: any) => Number(day)),
        deliveryType: dp?.deliveryType,
        productType: dp.productType && dp.productType?.split(","),
        overCapacity: Number(dp?.overCapacity),
        capacity: Number(dp?.capacity),
      })),
      // 8
      schedule: (schedule || [])?.map((sch: any) => ({
        deliveryType: sch?.deliveryType,
        shippingType: sch?.shippingType ? sch?.shippingType?.map((shp: any) => shp) : [],
        productType: sch.productType && sch.productType?.split(","),
        leadtime: {
          value: Number(sch?.leadtime?.value),
          unit: sch?.leadtime?.unit,
        },
        deliverySlots: parsed_delivery_slots_schedule_to_send(sch?.deliverySlots || []),
      })),
      // 9
      crossdock: (crossdock || []).map((crss: any) => ({
        productType: crss.productType && crss.productType?.split(","),
        value: Number(crss?.value),
        unit: crss?.unit,
      })),
      // 10
      workingSessions: (workingSessions || []).map((ws: any) => {
        let wsData = {
          productType: ws.productType && ws.productType?.split(","),
          capacity: Number(ws?.capacity),
          daysOfWeek: ws?.daysOfWeek.map((day: any) => Number(day)),
          from: ws.from,
          to: ws.to,
        }
        if (Boolean(ws.enable_locktime)) {
          wsData = Object.assign(wsData, {
            lockTime: {
              from: ws.lockTime?.from,
              to: ws.lockTime?.to,
            },
          })
        }
        return wsData
      }),
      // 11
      workingTime: (workingTime || [])?.map((wt: any) => ({
        daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day)),
        from: wt?.from,
        to: wt?.to,
      })),
      // 12
      calendarSettings: {
        timezone: calendarSettings?.timezone,
        nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => Number(day)) || [],
        nonWorkingDates: calendarSettings?.nonWorkingDates || [],
      },
    },
  }

  return data
}

const fill_form_delivery_slots_schedule_from_data = (delivery_slots: any) => {
  if (!Object.keys(delivery_slots)?.length) return []
  const _slots = Object.keys(delivery_slots || {}).reduce((acc: any, dayOfWeek: string) => {
    const slots = delivery_slots[dayOfWeek]
    slots.forEach((slot: any) => {
      acc.push({
        ...slot,
        capacity: Number(slot?.capacity),
        dayOfWeek: Number(dayOfWeek),
      })
    })
    return acc
  }, [])
  return _slots
}

const parsed_delivery_slots_schedule_to_send = (delivery_slots: Array<any>) => {
  if (!delivery_slots?.length) return delivery_slots
  let _data_slots = delivery_slots?.map((item: any) => ({
    label: item.label,
    from: item?.from,
    to: item?.to,
    capacity: Number(item?.capacity),
    status: item?.status,
    dayOfWeek: Number(item?.dayOfWeek),
  }))
  _data_slots = groupBy(_data_slots, "dayOfWeek")
  let _data_slots_parsed: any = {}

  Object.entries(_data_slots).forEach(([key, value]: any) => {
    _data_slots_parsed[key] = value?.map((sl: any) => ({
      label: sl.label,
      from: sl.from,
      to: sl.to,
      capacity: sl.capacity,
      status: sl.status,
    }))
  })
  return _data_slots_parsed
}

const loadDinamicFields = (field = {}) => {
  let values: any = []
  if (Object.keys(field).length > 0) {
    Object.entries(field).map(([key, value]: any) => {
      let valToPush = {
        key,
        type: getDataType(value),
        value: getDataType(value) === "array-string" ? value.join("+") : value,
      }
      if (value && typeof value === "object" && !Array.isArray(value)) {
        Object.entries(value).map(([key_in_value, val_in_value]: any) => {
          valToPush = {
            key: `${key}.${key_in_value}`,
            type: getDataType(val_in_value),
            value: getDataType(val_in_value) === "array-string" ? val_in_value.join("+") : val_in_value,
          }
          return values.push(valToPush)
        })
        return true
      }
      return values.push(valToPush)
    })
  }
  return values
}

const getDataType = (value: any) => {
  let dataType = typeof value
  switch (dataType) {
    case "object":
      const isArray = Array.isArray(value)
      return isArray && "array-string"
    case "number":
      return "number"
    case "string":
      return "string"
    case "boolean":
      return "boolean"
    default:
      return "string"
  }
}