import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import cn from 'classnames';
import { IoSearchSharp as Search, IoChevronDownSharp as ChevronDown } from "react-icons/io5";
import { MdOutlineClear as X } from "react-icons/md";
import { ImSpinner2 as Loader2 } from "react-icons/im";


interface Option {
  value: string;
  label: string;
}

interface SearchableSelectProps {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  options: Array<any>
  setOptions: Dispatch<SetStateAction<any>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  error?: boolean;
}

const SearchableSelect = React.forwardRef<HTMLDivElement, SearchableSelectProps>(
  ({ search, setSearch, options, setOptions, value, onChange, isLoading, setIsLoading, placeholder = 'Select an option...', className, error }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | undefined>();
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
      if (value) {
        const option = options?.find(opt => opt.value === value);
        if (option) {
          setSelectedOption(option);
        }
      } else {
        setSelectedOption(undefined);
      }
    }, [value, options]);

    const handleSelect = (option: Option) => {
      onChange(option.value);
      setSelectedOption(option);
      setSearch('');
      setIsOpen(false);
    };

    const handleClear = (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange('');
      setSelectedOption(undefined);
      setSearch('');
      setOptions([]);
      inputRef.current?.focus();
    };

    return (
      <div 
        ref={containerRef} 
        className={cn(
          'relative w-full',
          className
        )}
      >
        <div
          onClick={() => {
            setIsOpen(!isOpen);
            if (!isOpen) {
              setTimeout(() => inputRef.current?.focus(), 0);
            }
          }}
          className={cn(
            'flex items-center justify-between w-full px-3 py-2 text-sm border rounded-lg bg-white',
            'hover:border-gray-400 cursor-pointer transition-colors duration-200',
            error ? 'border-red-500' : 'border-gray-300',
            isOpen && 'ring-2 ring-blue-100 border-blue-500'
          )}
        >
          <div className="flex items-center flex-1 min-w-0">
            <Search className="w-4 h-4 text-gray-400 mr-2 flex-shrink-0" />
            {isOpen ? (
              <input
                ref={inputRef}
                type="text"
                className="w-full border-none p-0 outline-none focus:outline-none bg-transparent placeholder:text-gray-400"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                placeholder={placeholder}
              />
            ) : (
              <span className={cn(
                'truncate',
                !selectedOption && 'text-gray-400'
              )}>
                {selectedOption ? selectedOption.label : placeholder}
              </span>
            )}
          </div>
          <div className="flex items-center gap-1">
            {isLoading && (
              <Loader2 className="w-4 h-4 text-gray-400 animate-spin" />
            )}
            {selectedOption && !isLoading && (
              <button
                onClick={handleClear}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-4 h-4 text-gray-400" />
              </button>
            )}
            <ChevronDown className={cn(
              'w-4 h-4 text-gray-400 transition-transform duration-200',
              isOpen && 'transform rotate-180'
            )} />
          </div>
        </div>

        {isOpen && (
          <div className="absolute z-50 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
            <div className="max-h-60 overflow-auto">
              {isLoading ? (
                <div className="px-3 py-4 text-sm text-gray-500 flex items-center justify-center">
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Loading...
                </div>
              ) : options.length === 0 ? (
                <div className="px-3 py-2 text-sm text-gray-500">
                  {search.trim() ? 'No options found' : 'Start typing to search'}
                </div>
              ) : (
                options.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => handleSelect(option)}
                    className={cn(
                      'px-3 py-2 text-sm cursor-pointer',
                      'hover:bg-gray-100 transition-colors duration-200',
                      option.value === value && 'bg-blue-50 text-blue-600'
                    )}
                  >
                    {option.label}
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default SearchableSelect