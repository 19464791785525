import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationSchedule from "utils/yup/schedule_schema"
import { useFieldArray } from "react-hook-form"
import schedule_values from "utils/forms_default/schedule_values"
import FormSchedule from "components/FormEntities/FormSchedule"
import { parsed_body_to_send } from "components/FormEntities/FormSchedule/schedule.config"
import useScheduleResources from "hooks/entities/useScheduleResources"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, errors, handleSubmit, watch } = useForm({
    defaultValues: schedule_values,
    schema: schemaValidationSchedule,
  })
  const { set_loading_app, put_upsert_schedule_action } = useScheduleResources()
  const delivery_slots_field_form = useFieldArray({ control, name: "deliverySlots" })
  const rules_field_form = useFieldArray({ control, name: "rules" })
  const working_sessions_field_form = useFieldArray({ control, name: "sourceDefaultSettings.workingSessions" })

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      await put_upsert_schedule_action({ body })
      Swal.fire({
        title: "Hecho",
        text: "Agendamiento creado",
        icon: "success",
      })
      set_loading_app(false)
      navigate("/schedule")
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)


  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de agendamiento", href: "/schedule" },
          { name: "Creación de agendamiento", href: "/schedule/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de agendamiento" />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormSchedule
          control={control}
          errors={errors}
          watch={watch}
          delivery_slots_field_form={delivery_slots_field_form}
          rules_field_form={rules_field_form}
          working_sessions_field_form={working_sessions_field_form}
        />
      </form>
    </div>
  )
}

export default NewScreen
