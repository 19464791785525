import { AppContext } from "store/context/AppContext"
import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"

interface IUseFiltersTable {
  table_name: string
}

const useFiltersTable = ({ table_name }: IUseFiltersTable) => {
  const { filters_table } = useContext(AppContext)
  const selected_path = useLocation()

  useEffect(() => {
    filters_table.dispatch({ type: "CLEAR_FILTERS" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_path])

  const apply_filter = (value: any) => {
    if (Boolean(!value)) return
    filters_table.dispatch({ type: "ADD_FILTER", payload: value })
  }

  return {
    table_name,
    filters_table,
    apply_filter,
  }
}

export default useFiltersTable
