import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const DetailScreen = () => {
  const [item, set_data] = useState<any>()
  const { item_id } = useParams()
  const { data: data_items, search_osrm_action: search_osrm_action_items } = useSearchMiddlewareOSRM({
    entity: "items",
  })

  useEffect(() => {
    search_osrm_action_items({ from: 0, size: 1, filters: { sku: item_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_items?.records.length)) {
      const get_item = async () => {
        let item_from_req = data_items?.records[0]
        set_data(item_from_req)
      }
      get_item()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_items])

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de artículos", href: "/items" },
          { name: "Detalle de artículo", href: `/items/${item_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de artículo #${item_id}`} />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de item</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Nombre</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item?.name}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Retiro en tienda</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item?.storePickup ? "Si" : "No"}</p>
            </div>
          </div>
          <CollapseWithDetail title="Categorías" details={item?.categories} />
          <CollapseWithDetail title="Stock de seguridad" details={item?.safety} />
          <CollapseWithDetail title="Tiempos de preparación" details={item?.preparationTime} />
          <CollapseWithDetail title="Custom" details={item?.custom} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle del item</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">SKU</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item?.enabled ? "Si" : "No"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{item?.type}</p>
            </div>
          </div>
          <CollapseWithDetail title="Atributos logisticos" details={item?.logisticAttributes} />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
